import Waveform from "./Waveform";

import "./page.css"

export default function App() {
  // inject the link directly into the function
  function takeToLink(link) {
    return function() {
      window.open(link, "_blank");
    }
  }

  return (
    <div className="hdiv">
      <div style={{ display: "flex"}}>
      <img
        src="/logo.png"
        alt="logo"
        width={100}
        height={100}
        style={{ alignSelf: "center"}}
      />        
      <h1 className="title">CONVO</h1>
      </div>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center"}}>
        <p style={{ display: "flex", padding: "20px", fontSize: "1em" }}>
        Hear tailored audio conversations and spark new discussions with your voice!
        </p>
        <div className="ml-embedded" data-form="CrRsVK"></div>
        <p className="socials">
          follow us on...
        </p>
        <div className="button-grid">
          
          <div className="button" onClick={takeToLink("https://www.tiktok.com/@convo.app")}>
            <p>Tiktok</p>
          </div>
          <div className="button" onClick={takeToLink("https://x.com/thornsbees")}>
            <p>X</p>
          </div>
          <div className="button" onClick={takeToLink("https://www.youtube.com/channel/UCsHhz1PuHV-gv5WXJKnacZA")}>
            <p>Youtube</p>
          </div>
        </div>
        <h2 style={{fontWeight: "bolder", fontStyle: "italic", padding: "10px"}}>Overview</h2>
        <p style={{ display: "flex", padding: "20px", fontSize: "1em" }}>
        Dive into audio conversations that match your vibe. Convo brings you topics you love, all in one place. Listen, engage, and spark new discussions effortlessly.

Not just a listener? Jump in and share your thoughts! Reply to any part of a conversation and start fresh discussions with people everywhere. Your voice matters here.

Join us and change how you connect. Hear, engage, and transform your audio experience.
        </p>
        <Waveform/>
      </div>
    </div>
  );
}
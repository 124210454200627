import React from 'react';
import './Waveform.css'; // Import your CSS file containing styles for the waveform

const Waveform = () => {
  return (
    <div className="waveform-container">
      <svg className="waveform-svg" xmlns="http://www.w3.org/2000/svg">
        {/* SVG waveform code here */}
        <rect x="5%" y="0" width="3%" height="80" fill="white" />
        <rect x="15%" y="0" width="3%" height="60" fill="white" />
        <rect x="25%" y="0" width="3%" height="40" fill="white" />
        <rect x="35%" y="0" width="3%" height="20" fill="white" />
        <rect x="45%" y="0" width="3%" height="40" fill="white" />
        <rect x="55%" y="0" width="3%" height="60" fill="white" />
        <rect x="65%" y="0" width="3%" height="80" fill="white" />
        <rect x="75%" y="0" width="3%" height="60" fill="white" />
        <rect x="85%" y="0" width="3%" height="40" fill="white" />
        <rect x="95%" y="0" width="3%" height="20" fill="white" />
      </svg>
    </div>
  );
};

export default Waveform;